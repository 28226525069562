<template>
	<div>
		<s-crud
			title="Asignación de equipos"
			:config="config"
			:filter="filter"
			ref="scrudEquipmentAssignament"
			search=""
			 
		>
			<template v-slot:filter>
				<v-container>
					<v-row>
						<s-select-area 
							label="Area"
							v-model="filter.AreID"
						/>
					</v-row>
				</v-container>
			</template>
			<template v-slot:options>
				<v-tooltip bottom="">
					<template v-slot:activator="{ on }">
						<v-btn text v-on="on" small elevation="0" @click="addAssignment()">
							<i class="fas fa-chalkboard-teacher"></i>
						</v-btn>
					</template>
					<span>Agregar asignación</span>
				</v-tooltip>
			</template>
			 
			<template v-slot:AsgAction="{ row }">
			
				<v-btn
					x-small
					color="primary"
					@click="AsgViewCard(row)"
				>
				<!-- <i class="fas fa-chalkboard-teacher"></i> -->
				<v-icon style="font-size:14px;">far fa-eye</v-icon>

				</v-btn>	
				<v-btn
					x-small
					color="success"
					@click="downloadCharge(row)"
				>
					<i class="fas fa-download"></i>
				</v-btn>
			</template>
			
		</s-crud>

		<v-dialog
			v-model="dialogAssignment"
			v-if="dialogAssignment"
			fullscreen
			persistent
		>
			<v-card dense>
				<s-toolbar
					label="Asignaciones"
					color="#E0F7F2"
					close
					@close="closeDialogAssignment()"
				></s-toolbar>
				<v-row>
					<v-col>
						<v-card dense >
							<v-container>
								<v-row>
									<v-col class="s-col-form">
										<s-toolbar
											label="Datos del trabajador"
											color="#E0F7F2"
											save
											@save="saveAssignament()"
										></s-toolbar>
									</v-col>
								</v-row>
								<v-row>
									<v-col class="s-col-form">
										<!-- <s-toolbar-person
											@returnPerson="hadReturnPerson($event)"
											noEdit
										/> -->
										<s-search-person-crews
											return-object
											@returnPerson="hadReturnPerson($event)"
											:autofocus="true"
											:value="docNum"
										/>
									</v-col>
									<v-col class="s-col-form">
										<s-text
											readonly
											label="Trabajador"
											v-model="worker.NtpFullName"
										></s-text>
									</v-col>
								</v-row>
								<v-row>
									<v-col class="s-col-form">
										<s-date
											label="Fecha Inicio"
											v-model="worker.AsgBeginDate"
										></s-date>
									</v-col>
									<v-col class="s-col-form">
										<s-text
											label="Fecha Fin"
											v-model="worker.AsgEndDate"
											disabled
											   
										></s-text>
									</v-col>
								</v-row>
								<v-row>
									<v-col class="s-col-form">
										<s-text
											label="Hostname"
											v-model="worker.AsgHostName"
										></s-text>
									</v-col>
									<v-col class="s-col-form">
										<s-select-definition
											label="Accesorio"
											:def="1404"
											clearable
											v-model="worker.TypeAccessory"
										></s-select-definition>
									</v-col>
									<v-col class="s-col-form">
										<s-text
											label="N° Anydesk"
											v-model="worker.AsgAnydesk"
										></s-text>
									</v-col>
									<v-col class="s-col-form">
										<s-text
											label="Clave Anydesk"
											v-model="worker.AsgKeyAnydesk"
										></s-text>
									</v-col>
								</v-row>
								<v-row>
									
								</v-row>
								<v-row>
									<v-col>
										<s-textarea
											label="Observación"
											v-model="worker.AsgObservation"
										></s-textarea>
									</v-col>
								</v-row>
								<v-row>
									<v-col class="s-col-form">
										<s-toolbar
											label="Equipos asignados"
											color="#E0F7F2"
										></s-toolbar>
									</v-col>
								</v-row>
								<v-row>
									<v-col class="s-col-form">
										<v-data-table
											:headers="headersEquipmentAssignament"
											:items="itemsEquipmentAssignament"
										>
											<template v-slot:item.CptAction="{ item }">
												<v-btn
													x-small
													color="warning"
													@click="deleteEquipmentWorker(item)"
												>
													<i class="fas fa-trash"></i>
												</v-btn>
											</template>
										</v-data-table>
									</v-col>
								</v-row>
							</v-container>
						</v-card>
					</v-col>
					<v-col>
						<v-card dense>
							<v-container>
								<v-row>
									<v-col class="s-col-form">
										<s-toolbar
											label="Equipos sin asignar"
											color="#E0F7F2"
										></s-toolbar>
									</v-col>
								</v-row>
								<v-row>
									<v-col class="s-col-form">
										<v-data-table
											:headers="headersComponent"
											:items="itemsComponent"
											search-input
											:search="search"
										>
											<template v-slot:top>
												<v-text-field
													v-model="search"
													label="Search"
													class="mx-4"
												></v-text-field>
											</template>

											<template v-slot:item.CptAction="{ item }">
												<v-btn
													x-small
													color="success"
													@click="addEquipmentWorker(item)"
												>
													<i class="fas fa-plus"></i>
												</v-btn>
											</template>
										</v-data-table>
									</v-col>
								</v-row>
							</v-container>
						</v-card>
					</v-col>
				</v-row>
			</v-card>
		</v-dialog>

	</div>
</template>

<script>
	import _sAssig from '@/services/InformationTechnology/EquipmentAssigmentService';
	import _sAssigDetail from '@/services/InformationTechnology/EquipmentAssignamentDetailService';
	import _serviceComponent from "@/services/InformationTechnology/ComponentService";

	import sToolbarPerson from "@/components/Utils/Person/sToolbarPerson";
	import SSearchPersonCrews from "@/components/Utils/CrewsAssistance/sSearchPersonCrews.vue";

	export default {
		components: {
			sToolbarPerson,
			SSearchPersonCrews
		},
		
		data() {
			return {
				docNum: "",
				existAssignationWorker : false,
				itemsEquipmentAssignament: [],
				worker : {},
				search : "",
				itemsComponent : [],
				dialogAssignment : false,
				headersEquipmentAssignament : [
					
					{ text: "ID", value : "CptID"}, 
					{ text: "Tipo", value : "TypeComponentName"},
					{ text: "Marca", value : "TypeBrandName"},
					{ text: "Modelo", value : "CptModel"},
					{ text: "Serie", value : "CptSerie"},
					{ text: "Acción", value : "CptAction"},
				],
				filter : {},
				config: {
					model : {
						AsgID : "ID",
						CptAction : "",
						NtpDocumetnNumber : "",
						NtpFullName : "",
						AsgAction : ""
					},
					service: _sAssig,
					headers : [
					
						{text : "ID", value : "AsgID"},
						{text : "N° Doc.", value : "NtpDocumentNumber"},
						{text : "Trabajador", value : "NtpFullName"},
						{text : "Acción", value : "AsgAction"},
							 
					]
				},
				headersComponent : [
					{ text: "ID", value : "CptID"},
					{ text: "Tipo", value : "TypeComponentName"},
					{ text: "Marca", value : "TypeBrandName"},
					{ text: "Modelo", value : "CptModel"},
					{ text: "Serie", value : "CptSerie"},
					{ text: "Acción", value : "CptAction"},
				]
			}
		},

		methods: {
			AsgViewCard(row){
				// docNum
				
				this.addAssignment();


				// this.worker.NtpID = row.NtpID;
				// this.worker.NtpFullName = row.NtpFullName;
				// this.worker.AsgEndDate = row.AsgEndDate;
				// this.worker.AsgHostName = row.AsgHostName;
				// this.worker.AsgAnydesk = row.AsgAnydesk;
				// this.worker.AsgKeyAnydesk = row.AsgKeyAnydesk;
				// this.worker.AsgObservation = row.AsgObservation;
				row.ContractEndDate = row.AsgEndDate;
				this.hadReturnPerson(row);

			 	console.log("row", row)
				
			},
			downloadCharge(item)
			{
				_sAssig
				.deliverycharge(item, this.$fun.getUserID())
				.then(r => {
					this.$fun.downloadFile(
						r.data,
						this.$const.TypeFile.PDF,
						obj.NameReport
					);
				})
				
			},

			clearForm()
			{
				this.worker.NtpFullName = '';
				this.worker.AsgEndDate = '';
				this.worker.AsgHostName = '';
				this.worker.AsgAnydesk = '';
				this.worker.AsgKeyAnydesk = '';
				this.worker.AsgObservation = '';
				this.itemsEquipmentAssignament = [];
			},

			deleteEquipmentWorker(item)
			{
				this.$fun.alert("¿Seguro de liberar equipo del usuario?", "question")
				.then(r =>{
					if(r.value)
					{
						item.UsrUpdateID = this.$fun.getUserID();
						item.SecStatus = 0;
						_sAssigDetail
						.save(item, this.$fun.getUserID())
						.then(resp => {
							if(resp.status == 200)
							{
								this.$fun.alert("Liberado correctamente", "success");
								this.itemsEquipmentAssignament = this.itemsEquipmentAssignament.filter(element => {
									return element.CptID != item.CptID;
								})
								this.getItemsComponent();
								return;
							}
						})
					}
				})
			},

			addEquipmentWorker(item)
			{
				if(this.worker.NtpID == undefined)
				{
					this.$fun.alert("Debe de agregar al trabajador", "warning");
					return;
				}

				item.AsgID = this.existAssignationWorker ? this.worker.AsgID : 0;
				item.CptID = item.CptID;
				item.AsdDateReturn = this.worker.AsgEndDate;
				item.AsdStatus = 1;
				item.SecStatus = 1;
				item.UsrCreateID = this.$fun.getUserID();
				item.UsrUpdateID = this.$fun.getUserID();
				
				let existComponentInAssignation = this.itemsEquipmentAssignament.find(element =>{
					return element.CptID == item.CptID
				});
				if(existComponentInAssignation != null){
					this.$fun.alert("El componente ya esta agregado al usuario", "warning");
					return;
				}
				this.itemsEquipmentAssignament.push(item);
				this.worker.TicEquipmentAssignamentDetails = this.itemsEquipmentAssignament;
				this.itemsComponent = this.itemsComponent.filter(element => {
					return element.CptID != item.CptID;
				})
				
			},

			saveAssignament()
			{
				this.$fun.alert("¿Seguro de guardar?", "question")
				.then(r =>{
					if(r.value)
					{
						_sAssig
						.save(this.worker, this.$fun.getUserID())
						.then(resp => {
							if(resp.status == 200)
							{
								this.$fun.alert("Agregado correctamente", "success");
								this.clearForm();
								this.closeDialogAssignment();
								return;
							}
						})
					}
				})
			},

			hadReturnPerson(person)
			{
				if(person != null)
				{
					console.log("person", person);
					this.worker.NtpFullName = person.NtpPaternalSurname + ' ' + person.NtpMaternalSurname + ' ' + person.NtpName;
					this.worker.NtpID = person.NtpID;
					this.worker.NtpDocumentNumber = person.PrsDocumentNumber;
					this.worker.NtpPaternalSurname = person.NtpPaternalSurname;
					this.worker.NtpMaternalSurname = person.NtpMaternalSurname;
					this.worker.NtpName = person.NtpName;
					this.worker.CtrID = person.CtrID;
					this.worker.WkrID = person.WkrID;
					this.worker.AsgEndDate = person.ContractEndDate;

					this.worker.AsgStatus = 1;
					this.worker.SecStatus = 1;
					this.worker.UsrCreateID = this.$fun.getUserID();
					this.worker.UsrUpdateID = this.$fun.getUserID();
					this.searchEquipmentAssignamentByWorker();
				}
				
			},

			searchEquipmentAssignamentByWorker()
			{
				this.itemsEquipmentAssignament = [];
				let filter =  {
					AsgID : 0,
					NtpID : this.worker.NtpID,
					NtpDocumentNumber : ''
				}
				console.log('filterr ', filter);
				_sAssig
				.search(filter, this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200)
					{
						if(resp.data != null)
						{
							this.worker = resp.data;
							this.itemsEquipmentAssignament = resp.data.TicEquipmentAssignamentDetails;
							this.existAssignationWorker = true;
						}
					}
				})
			},

			addAssignment(items) {
				this.dialogAssignment = true;
				this.worker.TypeAccessory = null;
			},

			closeDialogAssignment()
			{
				this.dialogAssignment = false;
				this.$refs.scrudEquipmentAssignament.refresh();
				this.clearForm();
			},

			getItemsComponent()
			{
				let obj = {
					TypeComponent : 0,
					TypeBrand : 0,
					Search : ""
				}
				_serviceComponent
				.list(obj, this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200)
					{
						this.itemsComponent = resp.data;
					}
				})
			}
		},

		mounted () {
			this.getItemsComponent();
		},
		watch:{
			docNum(){
				 console.log("cambio")
			}
		},
	}
</script>